import React, { useContext, useRef, useState } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import axios from 'axios';
import { CiEdit } from 'react-icons/ci';
import { MdDeleteOutline } from 'react-icons/md';

const CustomTable = ({ headings, data = [], updateFn, deleteFn, queryKey }) => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <table className='tbl mt-4'>
        <thead>
          <tr>
            <th className='text-sm'>S.N.</th>
            {/* <td className='hidden'></td> */}
            {headings?.map((heading) => (
              <td key={heading} className='text-base'>{heading}</td>
            ))}
            {user?.role === 'admin' && <td className='w-60 text-base'>Action</td>}
          </tr>
        </thead>
        <tbody>
          {data?.length < 1 ? (
            <tr>
              <td>No data available</td>
            </tr>
          ) : (
            data?.map((row, i) => (
              <TR
                key={row.id}
                data={row}
                index={i}
                user={user}
                updateFn={updateFn}
                deleteFn={deleteFn}
                queryKey={queryKey}
              />
            ))
          )}
        </tbody>
      </table>
    </>
  );
};

export default CustomTable;

const TR = ({ data, index, user, updateFn, deleteFn, queryKey }) => {
  const { id } = data;
  const [isEdit, setIsEdit] = useState(false);

  const [changedValues, setChangedValues] = useState(data);

  const abortControllerRef = useRef(null);

  const queryClient = useQueryClient();

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsEdit(false);
  };

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: async () => {
      abortControllerRef.current = new AbortController();
      return updateFn(changedValues, abortControllerRef.current.signal);
    },

    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast.success('Item updated.');
      setIsEdit(false);
    },
    onError: (err) => {
      if (axios.isCancel(err)) {
        toast.info('Request cancelled.');
      } else {
        toast.error(err.response.data.message);
      }
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteFn,
    mutationKey: queryKey,
    onSuccess: (res) => {
      toast.success(res.message);
      queryClient.invalidateQueries(queryKey);
    },
    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });
  async function handleUpdate(e) {
    e.preventDefault();

    await updateMutation.mutate();
  }
  async function handleDelete(e) {
    e.preventDefault();
    setIsEdit(false);
    const isSure = window.confirm('Delete pricing?');
    isSure && (await deleteMutation.mutate(id));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setChangedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  return (
    <tr className='text-gray-600'>
      <td className='!w-8'>{index + 1}.</td>
      {Object.entries(data).map(([key, val]) => (
        <td key={key} className={key === 'id' ? 'hidden' : 'font-medium'}>
          <div className='flex gap-2 items-center'>
            {key === 'amount' && (
              <span className='text-sm font-normal '>NPR</span>
            )}{' '}
            {''}
            {isEdit ? (
              <input
                type='text'
                name={key}
                value={changedValues[key]}
                onChange={handleChange}
                className='form-input w-full'
              />
            ) : (
              <p className='px-3 py-1  border border-transparent w-full'>
                {val}
              </p>
            )}
          </div>
        </td>
      ))}

      {user?.role === 'admin' && (
        <td>
          {isEdit ? (
            <div className='flex gap-3'>
              <button
                type='button'
                onClick={handleCancel}
                className='cancleBtn'
              >
                Cancle
              </button>
              <button
                type='button'
                onClick={handleUpdate}
                className={`updateBtn 
                ${
                  updateMutation.status === 'pending'
                    ? ' cursor-not-allowed'
                    : ''
                }`}
              >
                Update
              </button>
            </div>
          ) : (
            <div className='flex items-center gap-6'>
              <button
                type='button'
                onClick={() => setIsEdit(true)}
                className={`editBtn`}
              >
                <CiEdit size={22} />
              </button>
              <button
                type='button'
                onClick={handleDelete}
                className={`deleteBtn ${
                  deleteMutation.status === 'pending'
                    ? ' cursor-not-allowed'
                    : ''
                }`}
              >
                <MdDeleteOutline size={22} />
              </button>
            </div>
          )}
        </td>
      )}
    </tr>
  );
};
