

import React from 'react';
import { square } from 'ldrs'
square.register()


const Loader = () => {
  return (
    <div className='h-[100vh] w-[100%] flex justify-center items-center bg-[--lightGreen]'>
      <l-square
        size='40'
        stroke='5'
        stroke-length='0.40'
        bg-opacity='0.1'
        speed='5'
        color='green'
      ></l-square>
    </div>
  );
};

export default Loader;
