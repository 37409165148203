import React from 'react';

const Smile = () => {
  return (
    <section className='bg-[--lightGreen]'>
      <div className='sectionWidth'>
        <div className='px-6 py-8 w-full'>
          <div className='flex flex-col md:pb-6  justify-center items-center text-center relative md:gap-14'>
            <h1 className='textBig text-green-600  '>
              Your Perfect Smile <br />
              <span className='text-[--colorBlack] border-b-2 md:text-lg  border-gray-700'>
                With
              </span>{' '}
              Neat Dental Care
            </h1>

            <p className='textLg md:w-[70%] text-center'>
              We take pride in providing excellent dental services with
              experienced doctors who are committed to your oral health. With a
              passion for precision and patient care, our team offers a
              comprehensive range of treatments to ensure your smile remains
              healthy and beautiful. Trust us to deliver exceptional dental care
              in a warm and welcoming environment. Your satisfaction is our top
              priority.
            </p>
            <div className='h-[300px]'>
              <img
                src='/images/smile2.png'
                alt='Neat Dental Care'
                className='  w-full h-full object-contain '
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Smile;
