import axios from 'axios';

export async function createAppointment(data) {
    // console.log(data);
  return await axios.post('/api/v1/appointment', data, {
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function getAppointments(name = '', currentPage = 1, type = '') {
    
  const response = await axios.get(`/api/v1/appointments?keyword=${name}&page=${currentPage}&type=${type}`).then((res) => res.data);

  // console.log(name, currentPage, response);
  return response
}

export async function deleteAppointment(id) {
  return await axios
    .delete(`/api/v1/appointment/${id}`)
    .then((res) => res.data);
}


export async function doneAppointment(id){
  return await axios.patch(`/api/v1/appointment-done/${id}`).then(res=>res.data)
}