import React from 'react';

const SectionHeading = ({ heading, isWhite = false }) => {
  return (
    <div className='flex gap-2 items-baseline md:mx-0 mx-3'>
      <span className='h-16 w-1 bg-green-700 rounded-lg'></span>
      <span className='h-10 w-1  bg-green-500 rounded-lg'></span>
      <div className='flex flex-col'>
      <h1 className={`textXl ${isWhite ? 'text-white' : 'text-green-900'}`}>{heading}</h1>
      <span className=''></span>
      </div>
    </div>
  );
};

export default SectionHeading;
