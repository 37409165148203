import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext } from 'react';
import { getMe, loginUser, logoutUser } from '../api/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: user, isFetching, refetch } = useQuery({
    queryKey: ['user'],
    queryFn: getMe,
    staleTime: 60000, // Cache user data for 1 minute
    cacheTime: 5 * 60 * 1000, // Keep user data in cache for 5 minutes
    retry: false, // Retry once on failure
    enabled: true,
  });

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: async() => {
      queryClient.invalidateQueries(["user"]);
    await  refetch();
      navigate('/dashboard');
      toast.success('Welcome to Dashboard.');
    },
    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });

  const logoutMutation = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      queryClient.removeQueries(["user"]); // Clear the cached user data
      navigate('/login');
      toast.success('Logout successfully.');
    },
    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });



  return (
    <AuthContext.Provider
      value={{
        user,
        isFetching,
        logout: logoutMutation.mutate,
        login: loginMutation.mutate,
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
