import React from 'react';
import Quote from '../Helpers/Quote';
import SectionHeading from '../Helpers/SectionHeading';

const Intro = () => {
  return (
    <>
    <section className='pageGradient rounded-sm'>
      <div className='sectionWidth commonMargin'>
      <SectionHeading heading={'Welcome'} isWhite={true} />
      <div className='content flex md:flex-row justify-between flex-col gap-10 '>
          {/* Left-side content */}
          <div className='md:w-[55%] flex flex-col gap-5   rounded-md text-[--colorWhite]'>
              <div className='flex justify-between w-full'>
              <h1 className='textBig'>
                Where <span className='text-green-600 border-b '>Smiles </span> <br />
                Shine Bright!{' '}
              </h1>
              <img src="/images/teeth-like.png" alt="smile"  className='h-24 md:pr-10'/>
              </div>
              <p
                className='md:w-[90%] textLg text-[--mediumWhite]'
              >
              <b>Neat Dental Care Pvt. Ltd.</b>  is located at the heart of Pokhara city, Pokhara-9, Mahendrapool (Medical Line).
                Discover exceptional dental services and personalized care in
                our state-of-the-art clinic. Experience gentle treatments and
                optimal oral health. Your trusted partner for a beautiful and
                confident smile. Let's make your dental journey extraordinary!
              </p>


            <Quote quote={'Your Smile Begins Here'} isWhite={true} />

          </div>


          {/* Right-side image */}
          <div className=' shadow-inner shadow-white rounded-lg overflow-hidden  flex justify-end'>
            <img
              className='object-contain self-end md:h-[500px]  w-full'
              src='images/welcome.png'
              alt='Beautiful'
            />
          </div>

      </div>
      </div>

    </section>
    </>
  );
};

export default Intro;
