
import axios from 'axios'


export async function createMessage(data){
    // console.log(data);
    return await axios.post(`/api/v1/message`, data, {headers: {"Content-Type": "application/json"}}).then(res=>res.data)
}

export async function getMessages(){
    return await axios.get('/api/v1/messages').then(res=>res.data)
}

export async function deleteMessage(id){
    return await axios.delete(`/api/v1/message/${id}`).then(res=>res.data)
}