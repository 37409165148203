
export const contactInfo = {
  phone1: '061-591035',
  phone2: '+977 981-8601755',
  phone3: '+977 981-5100696',
  location : 'Pokhara-09, Mahendrapool (Medical Line)',
  email : 'neatdental01@gmail.com',
  socialLinks : {
    facebook : 'https://www.facebook.com/profile.php?id=100088530228027',
    instagram : '',
    tiktok : 'https://www.tiktok.com/@neat_dental_care?lang=en',
  }

}



export const teamMembers = [
  {
    name: 'Dr. Shreeti Napit',
    education: 'BDS',
    specification: 'Dental Surgeon',

    nmc: '28584',
    image: 'images/Female.png',
    socialLinks : {
      facebook : 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com'
    }
  },
  {
    name: 'Dr. Suraj Shrestha',
    education: 'BDS, MDS',
    specification: 'Orthodontics and Dentofacial Orthopedic',
    nmc: '13989',
    image: 'images/Avatar.png',
    socialLinks : {
      facebook : 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com'
    }
  },
  {
    name: 'Dr. Hari Dwa',
    education: 'BDS, MDS',
    specification: 'Orthodontics and Dentofacial Orthopedic',

    nmc: '9891',
    image: 'images/Avatar.png',
    socialLinks : {
      facebook : 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com'
    }
  },
  {
    name: 'Dr. Dhurba Chandra Paudel',
    education: 'BDS, MDS',
    specification: 'OMFS',

    nmc: '8350',
    image: 'images/Avatar.png',
    socialLinks : {
      facebook : 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com'
    }
  },
  // {
  //   name: "Dr. Maya Pun",
  //   education: "BDS (Dental Surgeon)",
  //   image: "images/Female.png",
  //   nmc : "31795"
  // },
  {
    name: 'Dr. Babita Pardhan',
    education: 'MDS ',
    specification: 'Conservative Dentestry and Endodontics',
    image: 'images/Female.png',
    nmc: '8350',
    socialLinks: {
      facebook: 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com',
    },
  },
  {
    name: 'Dr. Dilip Kunwar',
    education: 'BDS, MDS ',
    specification: 'Dental Surgeon',

    image: 'images/Avatar.png',
    nmc: '15680',
    socialLinks: {
      facebook: 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com',
    },
  },
  {
    name: 'Dr. Nitika Ranjit',
    education: 'BDS ',
    specification: 'Dental Surgeon',
    image: 'images/Female.png',
    nmc: '23531',
    socialLinks: {
      facebook: 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com',
    },
  },
  {
    name: 'Dr. Jeni Acharya',
    education: 'BDS',
    specification: 'Dental Surgeon',

    image: 'images/Female.png',
    nmc: '27250',
    socialLinks: {
      facebook: 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com',
    },
  },
];

export const otherMembers = [
  {
    name: 'Syam Bahadur Thapa',
    image: '/images/Avatar.png',
    position: 'Receptionist',
    socialLinks : {
      facebook : 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com'
    }
  },
  {
    name: 'Ashish Ale Magar',
    position: 'Clener',
    image: 'images/female.png',
    socialLinks : {
      facebook : 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com'
    }
  },
  {
    name: 'Rajesh Shrees',
    position: 'MD',
    image: 'images/avatar.png',
    socialLinks : {
      facebook : 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https:/twitter.com'
    }
  },
];

export const treatments = [
  {
    name: 'Periodontology',
    description:
      'Periodontics is the specialized field of dentistry that deals with the study of the supporting structures of teeth, such as the gums, alveolar bone, ligament, and cementum.',
    image: 'images/treatments/periodontics.jpg',
    nepali: 'गिजा सम्बन्धि',
  },
  {
    name: 'Oral and Maxillaofacial surgery',
    description:
      'These services involve the extraction of one or more of your teeth, especially wisdom teeth. Extraction of impacted teeth that are causing pain, infection, decay, or other dental problems is performed by a dentist or oral surgeon under anesthesia.',
    image: 'images/treatments/oral-and-maxillofacial.jpg',
    nepali: 'दाँत तथा मुखकाे सल्यक्रिया',
  },
  {
    name: 'Prostodontics',
    description:
      'This service involves restoring or replacing your natural teeth with artificial ones that look and function like real teeth.',
    image: 'images/treatments/prosthodontics.jpg',
    nepali: 'कृतिम दाँत सम्बन्धी',
  },
  {
    name: 'Endodontics',
    description:
      'Root canal treatment, composite restoration, and glass ionomer restoration.',
    image: 'images/treatments/endodontics.jpg',
    nepali: 'अार. सी. टि. तथा फिलिङ',
  },
  {
    name: 'Orthodontics',
    description:
      'Braces are orthodontic treatments designed to straighten and align teeth for improved oral health and aesthetic appearance.',
    image: 'images/treatments/orthodontics.jpg',
    nepali: 'बाङ्गाे दाँतलाइ तार लगाएर मिलाउने',
  },
  {
    name: 'Pedodontics',
    description:
      'Pedodontics is associated with the oral checkup, diagnosis, and treatment of children\'s teeth.',
    image: 'images/treatments/pedodontics.jpg',
    nepali: 'बच्चाहरूकाे दाँत सम्बन्धी',
  },
  {
    name: 'Dental Implants and Surgery',
    description:
      'This service involves replacing missing or damaged teeth with artificial ones that are attached to metal posts inserted into the jawbone.',
    image: 'images/treatments/dental-implant.png',
    nepali: 'डेन्टल इम्प्लान्ट तथा सल्यक्रिया',
  },
  {
    name: 'Dental Emergency',
    description:
      'Dental emergency refers to dental symptoms or conditions that require prompt treatment. Dental emergencies can arise due to various causes, such as accidents, injuries, tooth decay, and oral infections.',
    image: 'images/treatments/dental-emergency.jpg',
    nepali: 'अाकास्मिक दन्त सेवा',
  },
];






