import React from 'react';


import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import { contactInfo } from '../../data';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa6';

const Footer = () => {
  return (
    <>
      <footer
        className='bg-gradient-to-tr from-green-950 from-50% via-green-800 via-90% to-green-800 to-100% rounded-t-lg'
        // style={{
        //   backgroundImage: 'url("/images/cristalBall.jpg")',
        //   backgroundSize: "auto",
        //   backgroundAttachment: "fixed",
        //   backgroundPosition: "center",
        //   overflow: "hidden",
        //   width: "auto",
        // }}
      >
        <div className='sectionWidth'>
          <div className='content flex flex-col md:flex-row gap-8 justify-between'>
            <div className='text-white'>
              <h3 className='textLg'>
                Quick links
              </h3>
              <ul className='flex flex-col gap-1 mt-2 pl-6 text-[--mediumWhite]'>
                {quickLinks.map((link, i) => (
                  <li
                    key={i}
                    className='  cursor-pointer hover:text-green-400 text-gray-300'
                  >
                    <a href={link.link}>&#10919;&nbsp; {link?.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className=' text-white '>
              <h3
                className='text-lg tracking-wider
               font-medium uppercase  '
              >
                Contact Details
              </h3>
              <div className='flex flex-col gap-4 mt-4 pl-6 text-[--mediumWhite]'>
                <div className='flex gap-2 items-center'>
                  <FaMapMarkerAlt className='' />
                  <p>Mahendrapool, Pokhara</p>
                </div>
                <div className='flex items-center gap-2'>
                  <FaPhone className='' />
                  <p>
                    <a href='tel: +977061591035' className='hover:underline'>
                      061-591035{' '}
                    </a>
                    /
                    <a href='tel: +9779818601755' className='hover:underline'>
                      {' '}
                      +9779818601755
                    </a>
                  </p>
                </div>

                <div className='flex gap-2 items-center'>
                  <FaEnvelope className=' mt-1' />
                  <p>
                    <a
                      href='mailto: neatdental01@gmail.com'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='hover:underline'
                    >
                      neatdental01@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>


            <div className=' md:text-left flex h-full self-end  items-end gap-6'>
              <div className='flex socialLink justify-between items-center gap-5  '>
                <a
                  href={contactInfo?.socialLinks?.facebook}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex flex-col justify-center items-center gap-1 hover:text-green-800 text-green-200'
                >
                  <FaFacebook size={24} />

                  {/* <span className="text-sm ">Facebook</span> */}
                </a>
                <a
                  href={contactInfo?.socialLinks?.instagram}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-col justify-center items-center gap-1 hover:text-green-800 text-green-200'
                >
                  <FaInstagram size={24} />
                  {/* <span className="text-sm ">Whatsapp</span> */}
                </a>
                <a
                  href={contactInfo?.socialLinks?.tiktok}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex flex-col justify-center items-center gap-1 hover:text-green-800 text-green-200 relative top-0.5'
                >
                  <FaTiktok size={24} />
                  {/* <span className="text-sm  ">Tiktok</span> */}
                </a>
              </div>
              <img src='/logo.png' alt='Logo' className=' w-20' />
            </div>

          </div>
  
        </div>

        <div className='py-1 text-[--mediumWhite] text-center text-xs'>
          <p>
            © {new Date().getFullYear()} Neat Dental Care Pvt. Ltd. All rights
            reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;

const quickLinks = [
  { link: '/', name: 'Home' },
  { link: '/treatments', name: 'Treatments' },
  { link: '/aboutus', name: 'About' },
  { link: '/contactus', name: 'Contact' },
  { link: '/price', name: 'Pricing' },
  { link: '/appointment', name: 'Appointment' },
];
