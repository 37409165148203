import { treatments } from '../../data';
import SectionHeading from '../Helpers/SectionHeading';
import TreatmentCard from './TreatmentCard';

export default function Treatments() {
  return (
    <>
    <section className='bg-[--lightGreen] rounded-sm '>

      <div className='sectionWidth commonMargin'>
        <SectionHeading heading={'Our Treatments'} />
        <div className='content flex flex-wrap  md:gap-4 gap-8 justify-center w-full'>
          {treatments?.map((treatment) => (
            <TreatmentCard key={treatment.name} treatment={treatment} />
          ))}
        </div>
      </div>
    </section>
    </>
  );
}
