import React from 'react';
import { FaLocationDot, FaPhone } from 'react-icons/fa6';
import { contactInfo } from '../../data';


const Hero = () => {
  // useGSAP(() => {
  //   const tl = gsap.timeline();

  //   tl.from('#heroHeading', {
  //     y: -200,
  //     stagger: 0.1,
  //     duration: 1,
  //     opacity: 0,
  //     ease: 'power1.out',
  //   });

  //   tl.add(
  //     [
  //       gsap.from('#heroParagraph', {
  //         y: 100,
  //         duration: 1,
  //         opacity: 0,
  //         ease: 'power1.out',
  //       }),
  //       gsap.from('#heroBtn', {
  //         opacity: 0,
  //         scale: 0,
  //         ease: 'power1.out',
  //         duration: 1,
  //       }),

  //       gsap.from('#heroImg', {
  //         scale: 0,
  //         duration: 1,
  //         ease: 'power1.out',
  //       }),
  //     ],
  //     '-0.4s',
  //   );
  // }, []);


  return (
    <>
       <section
          className=' sectionWidth  z-[0] flex  gap-6 h-full md:flex-row flex-col  mx-auto justify-between rounded-2xl  md:mt-8 mt-6   text-white'
          style={{
            backgroundImage: 'url("/images/treatment.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            overflow: 'hidden',
            filter: 'brightness(1) contrast(1)',
          }}
        >
        <div
          className='content flex flex-col gap-6 h-full text-[--colorWhite]'
        >
          <h1 id='heroHeading' className='textBig'>
            Best{' '}
            <span className='text-green-500 border-b-2 border-white'>
              Quality
            </span>{' '}
            <br />
            Dental Treatment
          </h1>

          <div className='textXl flex flex-col gap-8 text-white   rounded-lg'>
            <p className='md:w-[60%]'>
              We specialized in a comprehensive range of dental treatments,
              ensuring your smile receives the care it deserves.
            </p>

            <div className='textBase flex flex-col gap-2'>
              <div className='flex items-center gap-1 '>
                <span>{contactInfo?.location}</span>
                <FaLocationDot className='text-green-500' />
              </div>
              <div className='flex gap-2 items-center'>
                <p className=''>{contactInfo?.phone1} / {contactInfo?.phone2} / {contactInfo?.phone3} </p>
                <FaPhone className='text-green-500' />
              </div>
            </div>
          </div>

          <a  href='#appointment'  className='mt-6 !z-[10] secondaryBtn hover:border-green-600 text-white '>
            Book an Appointment {''} &rarr;
          </a>
        </div>

        {/* <div>
          <img src="/images/herodent.png" alt="" className='w-[80%] mx-auto' />
        </div> */}
      </section>
    </>
  );
};

export default Hero;
