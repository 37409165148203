import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NotFound, Loader } from './components';
import { AuthContextProvider } from './context/AuthContext';

//lazy components
const LoginSignup = lazy(() => import('./components/Admin/Auth/LoginSignup'));
const FrontRoutes = lazy(() => import('./routes/FrontRoutes'));
const DashboardRoutes = lazy(() => import('./routes/DashboardRoutes'));

console.log(process.env.NODE_ENV);


export const baseLink = process.env.NODE_ENV === "development" ? 'http://localhost:5000' : 'https://www.neatdentalcare.com.np'



function App() {
  return (
    <>
      <div className='max-w-[--maxWidth] w-full mx-auto bg-[--lightGreen]'>
          <ToastContainer
            position='top-right'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        <AuthContextProvider>

          <Routes>
            <Route
              path='/*'
              element={
                <Suspense fallback={<Loader />}>
                  <FrontRoutes />
                </Suspense>
              }
            />
            <Route
              path='/dashboard/*'
              element={
                <Suspense fallback={<Loader />}>
                  <DashboardRoutes />
                </Suspense>
              }
            />

            <Route
              exact
              path='/login'
              element={
                <Suspense fallback={<Loader />}>
                  <LoginSignup />
                </Suspense>
              }
            />

            {/* page not found */}
            <Route
              path='*'
              element={
                <Suspense fallback={<Loader />}>
                  <NotFound />
                </Suspense>
              }
            />
          </Routes>
        </AuthContextProvider>
      </div>
    </>
  );
}

export default App;
