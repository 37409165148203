import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './Navbar.css';
import { baseLink } from '../../App';

const Navbar = () => {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`w-full top-0   text-gray-800 
          ${scroll > 400 ? 'navbar-visible z-50 bg-[--colorWhite] shadow-lg max-w-[--maxWidth]' : ''}
        `}
      >
        <nav className='sectionWidth flex items-center gap-1 justify-between  pt-2 pb-1 '>
          <div className='flex md:gap-2 gap-1 items-center'>
            <a href='/' className=''>
              <img
                href='/'
                src='/logo.png'
                alt='Logo'
                className='w-30 md:h-14 object-contain h-12'
              />
            </a>

            <div className='md:flex items-center   text-xs whitespace-nowrap'>
              <Link to='/'>
                <p className='font-bold  md:text-[18px] text-[16px] text-green-600 '>
                  Neat Dental Care Pvt. Ltd.
                </p>
                <p className='md:text-[14px] text-center font-normal   text-[11px] md:mt-1.5 mt-1 tracking-wide'>
                  "Your Smile Is Our Concern"
                </p>
              </Link>
            </div>
          </div>

          <ul
            onClick={() => setIsOpen(false)}
            className={`flex md:flex-row   flex-col gap-8 md:items-center md:relative absolute !z-50 ${
              isOpen
                ? ' top-16 items-end  w-full md:bg-transparent bg-slate-200 py-2 rounded-md'
                : 'md:translate-y-0 -translate-y-[100%]'
            }  `}
          >
            <li>
              <a
                href='#home'
                className={`navTabs `}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a href='#aboutus' className={`navTabs`}>
                About Us
              </a>
            </li>
            <li>
              <a href='#contactus' className={`navTabs`}>
                Contact Us
              </a>
            </li>
            <li>
              <a href='#appointment' className={`navTabs `}>
                Appointment
              </a>
            </li>
            <li>
              <a href='#pricing' className={`navTabs`}>
                Pricing
              </a>
            </li>
          </ul>

          {user ? (
            <Link to={'/dashboard/profile'}>
              <img
                src={baseLink + '/' + user?.avatar}
                alt={user.name}
                className='w-12 aspect-square p-0.5 border border-green-600 rounded-full'
              />
            </Link>
          ) : (
            <Link
              to={'/login'}
              className='border border-green-600/40 md:px-5 md:py-2 px-3 py-1 font-medium tracking-wide rounded-full text-gray-600 hover:bg-green-600/15 hover:border-green-600/60 transition-colors duration-300'
            >
              Login
            </Link>
          )}

          <button
            type='button'
            className=' md:hidden hover: focus:outline-none '
            onClick={toggleMenu}
          >
            <svg className='h-8 w-8 fill-current' viewBox='0 0 24 24'>
              {!isOpen ? (
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4 6H20V8H4V6ZM4 11H20V13H4V11ZM20 18H4V16H20V18Z'
                ></path>
              ) : (
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6 18H4V16H6V18ZM4 13H6V11H4V13ZM6 8H4V6H6V8ZM10 18H8V16H10V18ZM8 13H10V11H8V13ZM10 8H8V6H10V8ZM14 18H12V16H14V18ZM12 13H14V11H12V13ZM14 8H12V6H14V8ZM20 18H16V16H20V18ZM16 13H20V11H16V13ZM20 8H16V6H20V8Z'
                ></path>
              )}
            </svg>
          </button>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
