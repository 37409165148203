import React, { useState } from 'react';

const TreatmentCard = ({ treatment }) => {
  const [isOpen, setisOpen] = useState(false);
  return (
    <div
      className='shadow-lg rounded-lg overflow-hidden border hover:border-green-600 transition-all duration-300 h-fit cursor-pointer'
      onClick={() => setisOpen(!isOpen)}
    >
      <div className='group shadow-inner shadow-black/20 bg-green-50  overflow-hidden md:w-64 h-full'>
        <img
          src={treatment?.image}
          alt=''
          className='h-52 md:h-48 w-full  rounded-t-lg object-cover'
        />
        <div className='px-4 py-6 flex flex-col gap-6 '>
          <div className='flex flex-col gap-1 font-semibold text-gray-800 border-b-2 border-green-700 w-fit pb-2'>
            <h1>{treatment?.nepali}</h1>
            <h1>{treatment?.name}</h1>
          </div>
          <div className='relative'>
            <p
              className={` ${
                isOpen ? 'line-clamp-none' : 'line-clamp-2'
              }  text-gray-600`}
            >
              {treatment?.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentCard;
