import React, { useRef } from 'react';
import Quote from '../Helpers/Quote';
import useOnScreen from '../../hooks/useOnScreen';

const About = () => {
  const aboutRef = useRef(null);
  useOnScreen(aboutRef, '0px');

  return (
    <>
      <section
        className='pageGradient rounded-sm relative md:h-[600px] h-[550px]'
        id='aboutus'
        ref={aboutRef}
      >
        <div className='sectionWidth  flex items-center h-full w-full'>
          <img
            src='/images/smile1.png'
            alt='About Us'
            className='absolute left-0 top-0  h-full object-cover  brightness-[30%] md:brightness-90'
          />
          <div className='md:w-[60%] md:ml-auto flex flex-col form  gap-5'>
            <h1 className='textBig'>
              Best <span className='text-green-600 border-b'> Dental Care</span>{' '}
              <br /> That You Can Trust
            </h1>
            <p className='textLg text-[--mediumWhite]'>
              We are well equipped with advanced technology and staffed by
              experienced dentists. Our goal is to provide top-notch dental
              services, from routine check-ups to complex treatments, in a
              comfortable and patient-centered environment.
            </p>
            <Quote
              quote={'Your comfort and satisfaction are our top priorities'}
            />
          </div>
          {/* <div className="content md:w-[60%] float-right flex items-center justify-center bg-black  h-full">
      
          </div> */}
        </div>
      </section>
    </>
  );
};

export default About;
