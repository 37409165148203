import axios from 'axios';
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
export async function createStaff(memberData) {
  // console.log(memberData);
  return await axios
    .post('/api/v1/staff', memberData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export async function getStaff() {
  return await axios.get('/api/v1/staff').then((res) => res.data.staff);
}

export async function updateStaff({ id, staffData, socialLinks }, signal) {
  const values = { ...staffData, socialLinks };
  // console.log(values);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const promise = axios.patch(
    `/api/v1/staff/${id}`,
    values,

    config,
    {
      cancelToken: source.token,
    },
  );

  signal?.addEventListener('abort', () => {
    source.cancel('Mutation was cancelled by TanStack Query');
  });

  const response = await promise;
  return response.data;
}

export async function deleteStaff(id) {
  return await axios.delete(`/api/v1/staff/${id}`).then((res) => res.data);
}

export async function createDoctor(data) {
  // console.log(data);
  return await axios
    .post('/api/v1/doctor', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export async function getDoctors() {
  return await axios.get('/api/v1/doctor').then((res) => res.data.doctors);
}

export async function updadeDoctor({ id, staffData, socialLinks }, signal) {
  const values = { ...staffData, socialLinks };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const promise = axios.patch(
    `/api/v1/doctor/${id}`,
    values,

    config,
    {
      cancelToken: source.token,
    },
  );

  signal?.addEventListener('abort', () => {
    source.cancel('Mutation was cancelled by TanStack Query');
  });

  const response = await promise;
  return response.data;
}

export async function deleteDoctor(id) {
  const promise = axios.delete(`/api/v1/doctor/${id}`);

  const response = await promise;
  return response.data;
}
