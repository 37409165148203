import React from 'react';
import TeamCard from './TeamCard';
import SectionHeading from '../Helpers/SectionHeading';
import { useQuery } from '@tanstack/react-query';
import { getDoctors, getStaff } from '../../api/members';

const OurTeam = () => {
  const { data: teamDoctor, isLoading: doctorLoading } = useQuery({
    queryKey: ['teamDoctor'],
    queryFn: getDoctors,
  });

  const { data: teamStaff, isLoading: staffLoading } = useQuery({
    queryKey: ['teamStaff'],
    queryFn: getStaff,
  });

  return (
    <section className='bg-[--lightGreen] rounded-sm'>
      <div className='sectionWidth commonMargin'>
        <SectionHeading heading={'Meet Our Members'} />

        <div className='content'>
          <div className='flex flex-wrap gap-8 w-full'>
            {staffLoading ? (
              <p>Loading</p>
            ) : (
              <>
                {teamStaff?.map((member, i) => (
                  <TeamCard member={member} key={i} type='members' />
                ))}
              </>
            )}
          </div>
          <div className='mt-10'>
            <h1 className='text-lg font-semibold py-4'>
              Meet Our Certified & Experienced Doctors
            </h1>
            <div className='flex flex-wrap gap-8'>
              {doctorLoading ? (
                <p>Loading</p>
              ) : (
                <>
                  {teamDoctor?.map((member) => (
                    <TeamCard
                      member={member}
                      key={member.name}
                      type='doctors'
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
