// PhotoWithTitle.js
import React from 'react';
import SectionHeading from '../Helpers/SectionHeading';
import { useQuery } from '@tanstack/react-query';
import {  getPricing } from '../../api/pricing';
import CustomTable from '../Admin/Helpers/CustomTable';

const Price = () => {
  const { data: pricing, isLoading } = useQuery({
    queryKey: ['pricing'],
    queryFn: getPricing,
  });
  return (
    <>
      <section className='bg-[--lightGreen] rounded-sm' id='pricing'>
        <div className='sectionWidth commonMargin'>
          <div className='flex items-baseline justify-between'>
            <SectionHeading heading={'Pricing'} />
            <h1 className='textLg font-semibold text-green-600 mr-2'>
              Neat Dental Care Pvt. Ltd.
            </h1>
          </div>
          <div className='px-3'>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable headings={['Particulars', 'Price Rate']} data={pricing} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Price;
