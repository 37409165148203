
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { baseLink } from '../../App';

const TeamCard = ({ member, type = 'doctors' }) => {
  


  return (
    <div className='shadow-lg  rounded-lg overflow-hidden border hover:border-green-600 transition-all w-full md:w-72 duration-200'>
      <div
        className='group shadow-inner shadow-black/20 bg-green-50  overflow-hidden h-full w-full pb-6 relative'
      >
        <img
          src={
            member.avatar
              ? baseLink + '/' + member.avatar
              : '/images/avatar.png'
          }
          alt={member.name}
          className='h-64 w-full  rounded-t-lg object-cover'
        />
        <div className=' w-full p-4 flex flex-col gap-3'>

          {type === 'doctors' && (
            <div className='flex justify-between'>
              <h2 className='text-gray-500 font-semibold uppercase'>{member?.education}</h2>
              <h2 className='text-gray-500  '>
              NMC: {''}
              {member?.nmcNo}
            </h2>
            </div>
          )}
          {type === 'members' && (
            <h2 className='uppercase font-semibold text-gray-500'>
              {member?.position}
            </h2>
          )}
          <div className='flex flex-col gap-1'>
            <h1 className='text-xl text-green-700 group-hover:text-green-700 font-bold  w-fit '>
              {member.name}
            </h1>
            <span className='h-0.5 bg-green-600 w-24 rounded-full'></span>
          </div>

          {type === 'doctors' && (
            <h2 className=' text-gray-500 font-semibold '>
              {member?.specification}
            </h2>
          )}
        </div>
        <div
          className='absolute group-hover:bottom-1.5 -bottom-8 flex justify-center opacity-100 h-fit  w-full z-10 group-hover:flex gap-8 items-center group-hover:text-green-600 duration-500  text-gray-600'
        >
          <a
            href={JSON.parse(member.socialLinks).facebook}
            target='_blank'
            rel='npopener noreferrer'
            className={JSON.parse(member.socialLinks)?.facebook ? '' : 'cursor-not-allowed'}
          >
            <FaFacebook
              size={22}
              className=' hover:text-green-800 transition-colors duration-200'
            />
          </a>
          <a
            href={JSON.parse(member.socialLinks)?.instagram}
            target='_blank'
            rel='npopener noreferrer'
            className={JSON.parse(member.socialLinks)?.instagram ? '' : 'cursor-not-allowed'}

          >
            <FaInstagram
              size={22}
              className=' hover:text-green-800 transition-colors duration-200'
            />
          </a>
          <a
             href={JSON.parse(member.socialLinks)?.twitter}
             target='_blank'
             rel='npopener noreferrer'
             className={JSON.parse(member.socialLinks)?.twitter ? '' : 'cursor-not-allowed'}
          >
            <FaTwitter
              size={22}
              className=' hover:text-green-800 transition-colors duration-200'
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
