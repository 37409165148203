import React, { useRef, useState } from 'react';
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaTiktok,
} from 'react-icons/fa';

import SectionHeading from '../Helpers/SectionHeading';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMessage } from '../../api/message';
import { toast } from 'react-toastify';
import useOnScreen from '../../hooks/useOnScreen';
import Wave from '../Layout/Wave';
import { contactInfo } from '../../data';
import { FaInstagram } from 'react-icons/fa6';

export default function Contact() {
  const contactRef = useRef(null);
  useOnScreen(contactRef, '0px');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const { name, email, message } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationKey: ['messages'],
    mutationFn: createMessage,

    onSuccess: () => {
      toast.success('Thank you for your message. We will get you soon.');
      queryClient.invalidateQueries(['messages']);
    },

    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createMutation.mutate(formData);
  };

  return (

      <section
        className='pageGradient rounded-sm'
        id='contactus'
        ref={contactRef}
      >
        <div className='sectionWidth commonMargin'>
          <SectionHeading heading={'Contact us'} isWhite={true} />

          <div className='content flex flex-col md:flex-row gap-10 justify-between'>

          <form
              className='rounded-md md:w-1/2 text-[--colorWhite] form   '
              onSubmit={handleSubmit}
            >
              <div className='flex flex-col mb-4'>
                <label htmlFor='name' className='mb-2 '>
                  Full Name*
                </label>
                <input
                  type='text'
                  id='name'
                  className='form-input'
                  placeholder='Your Full Name'
                  required
                  name='name'
                  value={name}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col mb-4'>
                <label htmlFor='phoneNo' className='mb-2'>
                  Email*
                </label>
                <input
                  type='email'
                  id='email'
                  className='form-input'
                  placeholder='example@gmail.com'
                  required
                  name='email'
                  value={email}
                  onChange={handleChange}
                />
              </div>
              <div className='flex flex-col mb-4'>
                <label htmlFor='message' className='mb-2'>
                  Message*
                </label>
                <textarea
                  id='message'
                  className='form-input'
                  rows='3'
                  placeholder='Your message here...'
                  required
                  name='message'
                  value={message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                disabled={createMutation.isPending}
                type='submit'
                className={`primaryBtn flex justify-center items-center ${
                  createMutation.isPending
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                }
                  `}
              >
                {createMutation.isPending ? <Wave /> : 'Send'}
              </button>
            </form>


            <div className='w-full shadow-inner shadow-white px-4 py-6 flex flex-col gap-6 md:w-[50%] object-left  rounded-lg text-gray-300'>
              {/* location */}
              <div>
                <h2 className='textLg'>Location</h2>
                <div className='flex gap-1 items-center mt-2 pl-4 '>
                  <FaMapMarkerAlt className='text-green-600 mr-2 ' />
                  <p className='text-[--mediumWhite]'>
                    {contactInfo?.location}
                  </p>
                </div>
              </div>

              {/* contact info */}
              <div>
                <h2 className='textLg'>Contact Info</h2>
                <div className='flex gap-2 items-center mt-2 pl-4'>
                  <FaPhone className='text-green-600 mr-1 mt-1' />
                  <p className='text-[--mediumWhite]'> {contactInfo?.phone1} / {contactInfo?.phone2} / {contactInfo?.phone3}</p>
                </div>
                <div className='flex gap-2 items-center mt-2 pl-4'>
                  <FaEnvelope className='text-green-600 mr-1 mt-1' />
                  <p className='text-[--mediumWhite]'> {contactInfo?.email}</p>
                </div>
              </div>

              {/* social */}
              <div className=''>
                <h2 className='textLg'>Follow us on</h2>
                <div className='mt-4 pl-4 flex gap-4 '>
                  <a
                    href={contactInfo?.socialLinks?.facebook}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex justify-center items-center  flex-col gap-1 text-[--mediumWhite] hover:text-green-500 cursor-pointer'
                  >
                    <FaFacebook size={24} />
                    {/* <p>Facebook</p> */}
                  </a>
                  <a
                    href={contactInfo?.socialLinks?.instagram}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex justify-center items-center  flex-col gap-1 text-[--mediumWhite] hover:text-green-500 cursor-pointer'
                  >
                    <FaInstagram size={24} />
                    {/* <p>Facebook</p> */}
                  </a>

                  <a
                    href={contactInfo?.socialLinks?.tiktok}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex justify-between items-center flex-col gap-1 text-[--mediumWhite] hover:text-green-500 cursor-pointer'
                  >
                    <FaTiktok size={24} />
                    {/* <p>Tiktok</p> */}
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>

   
      </section>

  );
}
