import React, { useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createAppointment } from '../../api/appointment';
import { toast } from 'react-toastify';
import Wave from '../Layout/Wave';

// import { jsPDF } from "jspdf";

const Appointment = () => {
  const [checkDate, setCheckDate] = useState();
  const [visitDateErr, setVisitDateErr] = useState(false);

  const visitDateRef = useRef();

  const [formData, setFormData] = useState({
    name: '',
    number: '',
    message: '',
    email: '',
    visitDate: '',
  });

  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationKey: ['appointments'],
    mutationFn: createAppointment,
    onSuccess: (res) => {
      queryClient.invalidateQueries(['appointments']);
      toast.success('Booked successfully! We will respond you soon.');
    },
    onError: (err) => {
      toast.error(err.response.data.message);
    },
  });

  const { name, number, message, email } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckDate = (e) => {
    const selectedDateStr = e.target.value;
    const selectedDate = new Date(selectedDateStr);
    const today = new Date();

    // Reset the time of today to the start of the day for accurate comparison
    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      setCheckDate('');
      setVisitDateErr(true);
      visitDateRef.current.focus(); // Make sure to use .current to access the DOM node
    } else {
      // If it's a valid date, update the state
      setCheckDate(selectedDateStr);
      formData.visitDate = selectedDateStr;
      setVisitDateErr(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createMutation.mutate(formData);
  };

  return (
    <>
      <section
        id='appointment'
        className=''
        style={{
          backgroundImage: 'url("/images/cristalBall.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
        }}
      >
        <div className='sectionWidth commonMargin'>
          <div className='w-full p-2 flex flex-col gap-6'>
            <h1 className='textXl font-semibold text-white'>
              Book Your Visit At <br />{' '}
              <span className='textBig text-green-600 font-medium'>
                Neat Dental Care
              </span>
            </h1>

            <form
              className='md:w-[60%] mt-4  mx-auto form'
              onSubmit={handleSubmit}
            >
              <div className='flex flex-col md:flex-row w-full md:items-center gap-4'>
                <div className='flex flex-col mb-4 md:w-1/2'>
                  <label htmlFor='name' className='mb-2'>
                    Name*
                  </label>
                  <input
                    type='text'
                    id='name'
                    className='form-input'
                    placeholder='Full Name'
                    required
                    name='name'
                    value={name}
                    onChange={handleChange}
                  />
                </div>
                <div className='flex flex-col mb-4 md:w-1/2'>
                  <label htmlFor='number' className='mb-2'>
                    Phone Number*
                  </label>
                  <input
                    type='number'
                    id='number'
                    className='form-input'
                    placeholder='Phone Number'
                    required
                    name='number'
                    value={number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='flex flex-col mb-4'>
                <label htmlFor='number' className='mb-2'>
                  Email*
                </label>
                <input
                  type='email'
                  id='email'
                  className='form-input'
                  placeholder='example@example.com'
                  required
                  name='email'
                  value={email}
                  onChange={handleChange}
                />
              </div>

              <div className='flex flex-col mb-4'>
                <label htmlFor='visitDate' className='mb-2'>
                  Appointment Date*
                </label>
                <input
                  ref={visitDateRef}
                  type='date'
                  id='visitDate'
                  className='form-input w-full text-white'
                  required
                  name='visitDate'
                  value={checkDate}
                  onChange={handleCheckDate}
                />
                {visitDateErr && (
                  <span className='text-red-500 text-sm '>
                    Please Select Future Dates.
                  </span>
                )}
              </div>
              <div className='flex flex-col mb-4'>
                <label htmlFor='message' className='mb-2'>
                  Problem Details*
                </label>
                <textarea
                  id='message'
                  className='form-input'
                  rows='3'
                  placeholder='Problem details...'
                  required
                  name='message'
                  value={message}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                type='submit'
                className={`primaryBtn mt-4 flex items-center justify-center ${
                  createMutation.isPending
                    ? 'cursor-not-allowed opacity-50'
                    : ''
                }`}
                disabled={createMutation.isPending}
              >
                {createMutation.isPending ? <Wave /> : 'Book Now'}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Appointment;
