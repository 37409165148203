import React from 'react'
import { waveform } from 'ldrs';

waveform.register();


const Wave = () => {
  return (
    <l-waveform
    size='26'
    stroke='3.5'
    speed='2'
    color='green'
  ></l-waveform>
  )
}

export default Wave