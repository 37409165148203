import React from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ content, link }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={content} />

        <link rel="canonical" href={link} />
      </Helmet>
    </>
  );
};

export default MetaData;
