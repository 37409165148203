import axios from 'axios';


export async function registerUser(userData) {
  return await axios.post('http://localhost:5000/api/v1/register', userData).then((res) => res.data);
}

export async function loginUser(credentials) {
  return await axios.post('/api/v1/login', credentials).then((res) => res.data.user || null );
}



// export  async function getMe() {
//   return await axios.get('/api/v1/me').then((res) => res.data.user || null);
// }

export const getMe = async () => {
  try {
    const response = await axios.get('/api/v1/me');
    return response.data.user || null; // Adjust according to your backend response
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Unauthorized error
      return null;
    }
    throw error; // Rethrow other errors
  }
};

export async function logoutUser() {
  return await axios.get('/api/v1/logout').then((res) => res.data );
}

const config = {
  headers : {
    'Content-Type' : 'multipart/form-data'
}  
}

export function updateMe(userData){
  return axios.patch('/api/v1/updateuser',  userData, config ).then(res=>res.data)
}

export async function updatePassword({oldPassword, newPassword}){
  return await axios.put('/api/v1/update-password', {oldPassword, newPassword}, {headers: {
    "Content-Type": "application/json"
  }})
}