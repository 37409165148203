import axios from 'axios';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export async function newPricing(pricing) {
  return await axios
    .post('/api/v1/newPricing', pricing, config)
    .then((res) => res.data);
}

// export async function fetchTodos ({ signal }) => {
//   const CancelToken = axios.CancelToken;
//   const source = CancelToken.source();

//   const promise = axios.get('/todos', {
//     cancelToken: source.token,
//   });

//   signal?.addEventListener('abort', () => {
//     source.cancel('Query was cancelled by TanStack Query');
//   });

//   const response = await promise;
//   return response.data;
// };

export async function updatePricing(data , signal) {
  // console.log(data);
  
  const {id, particular, amount} = data;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const promise = axios.patch(`/api/v1/pricing/${id}`, {particular, amount}, {
    cancelToken: source.token,
  }, config);

  signal?.addEventListener('abort', () => {
    source.cancel('Mutation was cancelled by TanStack Query');
  });

  const response = await promise;
  return response.data;
};


export async function getPricing() {
  return await axios.get('/api/v1/pricing').then((res) => res.data.pricing);
}

// export async function updatePricing({id, pricing}) {
//     return await axios.patch(`/api/v1/pricing/${id}`, pricing, {
//       headers: { 'Content-Type': 'application/json' },
//     }).then(res=>res.data);
// }
export async function deletePricing(id) {
  return await axios.delete(`/api/v1/pricing/${id}`).then((res) => res.data);
}
