import Hero from './Hero';
import Intro from './Intro';
import Smile from './Smile';
import Treatments from '../Treatments';
import About from '../About';
import Contact from '../Contact';
import OurTeam from '../OurTeam';
import Appointment from '../Appointment';
import Price from '../Price';


export default function Home() {
  return (
    <>
      <div className='flex flex-col gap-4 mb-20'>
        <Hero />
        <Intro />
        <Smile />
        <Treatments />
        <About />
        <OurTeam />
        <Contact />
        <Appointment />
        <Price />
        
      </div>
    </>
  );
}
